<template>
  <v-overlay :value="overlay">
    <div style="position: relative">
      <v-progress-circular
        indeterminate
        color="#00a650"
        size="120"
        style="position: absolute; left: -350px; bottom: -500"
      >
        <!-- <div style="text-align: left;"
          v-text="count === undefined ? 'Verifying...Please wait...' : count"
        ></div>
        <div class="PrimaryFontColorXSmall black--text" v-if="count !== undefined" style="margin-left: 100px;">Verifying...Please wait...</div> -->
      </v-progress-circular>
   
    </div>
  </v-overlay>
</template>

<script>
export default {
  props: {
    overlay: Boolean,
    count: Number,
  },
};
</script>
<!-- 
   <style scoped>
   .container {
     width: 100%;
     display: flex;
     justify-content: center;
     align-items: center;
   }
   
   .tree {
     position: relative;
     width: 50px;
     height: 50px;
     transform-style: preserve-3d;
     transform: rotateX(-20deg) rotateY(30deg);
     animation: treeAnimate 5s linear infinite;
   }
   
   @keyframes treeAnimate {
     0% {
       transform: rotateX(-20deg) rotateY(360deg);
     }
   
     100% {
       transform: rotateX(-20deg) rotateY(0deg);
     }
   }
   
   .tree div {
     position: absolute;
     top: -50px;
     left: 0;
     width: 100%;
     height: 100%;
     transform-style: preserve-3d;
     transform: translateY(calc(25px * var(--x))) translateZ(0px);
   }
   
   .tree div.branch span {
     position: absolute;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     background: linear-gradient(90deg, #69c069, #77dd77);
     clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
     border-bottom: 5px solid #00000019;
     transform-origin: bottom;
     transform: rotateY(calc(90deg * var(--i))) rotateX(30deg) translateZ(28.5px);
   }
   
   .tree div.stem span {
     position: absolute;
     top: 110px;
     /* updated top value */
     left: calc(50% - 7.5px);
     width: 15px;
     height: 50%;
     background: linear-gradient(90deg, #bb4622, #df7214);
     border-bottom: 5px solid #00000019;
     transform-origin: bottom;
     transform: rotateY(calc(90deg * var(--i))) translateZ(7.5px);
   }
   
   .shadow {
     position: absolute;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     background: rgba(0, 0, 0, 0.4);
     filter: blur(20px);
     transform-style: preserve-3d;
     transform: rotateX(90deg) translateZ(-65px);
   }
   </style> -->
