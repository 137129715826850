import { RLGetCurrentUserDetails } from "@/graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
import RouterList from "@/JsonFiles/RouterList.json";
export const GetCurrentUser = {
  data() {
    return {
      getCurrentUserDetailsObject: {},
      getCurrentUserDetailsArray: [],
      overlay: false,
      RouterList: [],
      user_type: "",
      error_Type: "",
      errorMessage: "",
    };
  },
  methods: {
    async GetCurrentUser() {
      try {
        this.overlay = true;
        this.Expand = false;
        let result = await API.graphql(
          graphqlOperation(RLGetCurrentUserDetails, {
            input: {
              user_email_id: this.$store.getters.get_user_email,
            },
          })
        );

        this.$store.commit("SET_CURRENTUSER_DETAILS", {});
        this.$store.commit(
          "SET_CURRENTUSER_DETAILS",
          JSON.parse(result.data.RLGetCurrentUserDetails).data.items[0]
        );
        this.getCurrentUserDetailsObject = JSON.parse(
          result.data.RLGetCurrentUserDetails
        ).data.items[0];
        // console.log(
        //   "RLGetCurrentUserDetails:-->",
        //   this.getCurrentUserDetailsObject
        // );
        this.user_type ==
          JSON.parse(result.data.RLGetCurrentUserDetails).data.items[0]
            .user_type;
        this.$store.commit(
          "SET_USER_TYPE",
          JSON.parse(result.data.RLGetCurrentUserDetails).data.items[0]
            .user_type
        );
        if (this.$route.name == "LandingPage" || this.$route.name == "Users") {
          this.$router.push("/Dashboard");
        }
        // localStorage.setItem("UserEmail", JSON.stringify(this.$store.getters.get_user_email));
        this.$store.commit(
          "SET_ACCOUNT_LOGO_URL",
          this.getCurrentUserDetailsObject.issuer_details.account_logo_url
        );
        this.overlay = false;
        this.Expand = true;
        this.RouterList = RouterList;
      } catch (error) {
        console.log(error);
        console.log(error.errors[0].message);
        this.error_Type = error.errors[0].errorType;
        this.errorMessage = error.errors[0].message;
        this.overlay = false;
        this.Expand = true;
      }
    },
    async GetCurrentUser2() {
      try {
        this.overlay = true;
        this.Expand = false;
        let result = await API.graphql(
          graphqlOperation(RLGetCurrentUserDetails, {
            input: {
              user_email_id: this.$store.getters.get_user_email,
            },
          })
        );

        this.$store.commit("SET_CURRENTUSER_DETAILS", {});
        this.$store.commit(
          "SET_CURRENTUSER_DETAILS",
          JSON.parse(result.data.RLGetCurrentUserDetails).data.items[0]
        );
         (this.getCurrentUserDetailsArray = JSON.parse(
          result.data.RLGetCurrentUserDetails
        ).data.items[0].user_type);
        // console.log(
        //   "RLGetCurrentUseArrraails:-->",
        //   this.getCurrentUserDetailsArray
        // );
        
        this.user_type ==
          JSON.parse(result.data.RLGetCurrentUserDetails).data.items[0]
            .user_type;
        this.$store.commit(
          "SET_USER_TYPE",
          JSON.parse(result.data.RLGetCurrentUserDetails).data.items[0]
            .user_type
        );
        if (this.$route.name == "LandingPage" || this.$route.name == "Users") {
          this.$router.push("/Dashboard");
        }
        // localStorage.setItem("UserEmail", JSON.stringify(this.$store.getters.get_user_email));
        this.$store.commit(
          "SET_ACCOUNT_LOGO_URL",
          this.getCurrentUserDetailsObject.issuer_details.account_logo_url
        );
        this.overlay = false;
        this.Expand = true;
        this.RouterList = RouterList;return (this.getCurrentUserDetailsArray = JSON.parse(
          result.data.RLGetCurrentUserDetails
        ).data.items[0]);
      } catch (error) {
        // console.log(error);
        // console.log(error.errors[0].message);
        this.error_Type = error.errors[0].errorType;
        this.errorMessage = error.errors[0].message;
        this.overlay = false;
        this.Expand = true;
      }
    },
  },
};
