<template>
  <v-dialog v-model="dialogAdditionalDetails" persistent max-width="700px">
    <v-card class="overflow-y-auto">
      <v-toolbar dark color="secondary" dense class="elevation-0">
        <div>Additional Details of Employee</div>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeAdditionalDetailsEmit()"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </v-toolbar>
      <v-card-text v-if="details == true" class="font-weight-bold mt-8"
        >No Additional Details Found</v-card-text
      >
      <v-card-text class="mt-8">
        <v-row class="">
          <v-col cols="7" md="7" class="mt-n9" v-if="StoreObj.salary != ''"
            ><v-card-subtitle>Last Drawn CTC</v-card-subtitle></v-col
          >
          <v-col cols="5" md="5" class="mt-n9" v-if="StoreObj.salary != ''"
            ><v-card-subtitle class="font-weight-bold">{{
              StoreObj.salary
            }}</v-card-subtitle></v-col
          >
        </v-row>
        <v-row class="">
          <v-col
            cols="7"
            md="7"
            class="mt-n9"
            v-if="StoreObj.remuneration != ''"
            ><v-card-subtitle>Additional Remuneration</v-card-subtitle></v-col
          >
          <v-col
            cols="5"
            md="5"
            class="mt-n9"
            v-if="StoreObj.remuneration != ''"
            ><v-card-subtitle class="font-weight-bold">
              <span>{{ StoreObj.remuneration }}</span></v-card-subtitle
            ></v-col
          >
        </v-row>
        <v-row class="">
          <v-col
            cols="7"
            md="7"
            class="mt-n9"
            v-if="StoreObj.supervisordetails != ''"
            ><v-card-subtitle
              >Supervisor's Name</v-card-subtitle
            ></v-col
          >
          <v-col
            cols="5"
            md="5"
            class="mt-n9"
            v-if="StoreObj.remuneration != ''"
            ><v-card-subtitle class="font-weight-bold">
              <span>{{ StoreObj.supervisordetails }}</span></v-card-subtitle
            ></v-col
          >
        </v-row>
        <v-row class="">
          <v-col
            cols="7"
            md="7"
            class="mt-n9"
            v-if="StoreObj.reasonofleaving != ''"
            ><v-card-subtitle>Reason of Leaving</v-card-subtitle></v-col
          >
          <v-col
            cols="5"
            md="5"
            class="mt-n9"
            v-if="StoreObj.reasonofleaving != ''"
            ><v-card-subtitle class="font-weight-bold">
              <span>{{ StoreObj.reasonofleaving }}</span></v-card-subtitle
            ></v-col
          >
        </v-row>
        <v-row class="">
          <v-col
            cols="7"
            md="7"
            class="mt-n9"
            v-if="StoreObj.natureofemployment != ''"
            ><v-card-subtitle>Nature of Employment</v-card-subtitle></v-col
          >
          <v-col
            cols="5"
            md="5"
            class="mt-n9"
            v-if="StoreObj.natureofemployment != ''"
            ><v-card-subtitle class="font-weight-bold">{{
              StoreObj.natureofemployment
            }}</v-card-subtitle></v-col
          >
        </v-row>
        <v-row class="">
          <v-col
            cols="7"
            md="7"
            class="mt-n9"
            v-if="StoreObj.lastlocationofwork != ''"
            ><v-card-subtitle>Last Location Of Work</v-card-subtitle></v-col
          >
          <v-col
            cols="5"
            md="5"
            class="mt-n9"
            v-if="StoreObj.lastlocationofwork != ''"
            ><v-card-subtitle class="font-weight-bold">
              <span>{{ StoreObj.lastlocationofwork }}</span></v-card-subtitle
            ></v-col
          >
        </v-row>
        <v-row class="">
          <v-col cols="7" md="7" class="mt-n9" v-if="StoreObj.dues != ''"
            ><v-card-subtitle
              >Exit Formalities Completed</v-card-subtitle
            ></v-col
          >
          <v-col cols="5" md="5" class="mt-n9" v-if="StoreObj.dues != ''"
            ><v-card-subtitle class="font-weight-bold">
              <span>{{ StoreObj.dues }}</span></v-card-subtitle
            ></v-col
          >
        </v-row>
        <v-row class="">
          <v-col
            cols="7"
            md="7"
            class="mt-n9"
            v-if="StoreObj.commentDuties != ''"
            ><v-card-subtitle v-if="StoreObj.commentDuties != ''"
              >Duties and Responsibilities</v-card-subtitle
            ></v-col
          >
          <v-col
            cols="5"
            md="5"
            class="mt-n9"
            v-if="StoreObj.commentDuties != ''"
            ><v-card-subtitle class="font-weight-bold">
              <span>{{ StoreObj.commentDuties }}</span></v-card-subtitle
            ></v-col
          >
          <!-- <v-col cols="4" md="4" class="mt-n9" v-if="StoreObj.scoreDuties != ''">
            <v-rating
              v-model="StoreObj.scoreDuties"
              background-color="green"
              color="green"
              small
            ></v-rating>
          </v-col> -->
        </v-row>
        <v-row class="">
          <v-col
            cols="7"
            md="7"
            class="mt-n9"
            v-if="StoreObj.commentAttitude != ''"
            ><v-card-subtitle>Attitude and Reputation</v-card-subtitle></v-col
          >
          <v-col
            cols="5"
            md="5"
            class="mt-n9"
            v-if="StoreObj.commentAttitude != ''"
            ><v-card-subtitle class="font-weight-bold">
              <span>{{ StoreObj.commentAttitude }}</span></v-card-subtitle
            ></v-col
          >
          <!-- <v-col cols="4" md="4" v-if="StoreObj.scoreAttitude != ''">
            <v-rating
              v-model="StoreObj.scoreAttitude"
              background-color="green"
              color="green"
              small
            ></v-rating>
          </v-col> -->
        </v-row>
        <v-row class="">
          <v-col
            cols="7"
            md="7"
            class="mt-n9"
            v-if="StoreObj.commentPerformance != ''"
            ><v-card-subtitle>Performance</v-card-subtitle></v-col
          >
          <v-col
            cols="5"
            md="5"
            class="mt-n9"
            v-if="StoreObj.commentPerformance != ''"
            ><v-card-subtitle class="font-weight-bold">
              <span>{{ StoreObj.commentPerformance }}</span></v-card-subtitle
            ></v-col
          >
          <!-- <v-col cols="4" md="4" class="mt-n9" v-if="StoreObj.scorePerformance != ''">
            <v-rating
              v-model="StoreObj.scorePerformance"
              background-color="green"
              color="green"
              small
            ></v-rating>
          </v-col> -->
        </v-row>
        <v-row class="">
          <v-col
            cols="7"
            md="7"
            class="mt-n9"
            v-if="StoreObj.commentIntegrity != ''"
            ><v-card-subtitle>Integrity</v-card-subtitle></v-col
          >
          <v-col
            cols="5"
            md="5"
            class="mt-n9"
            v-if="StoreObj.commentIntegrity != ''"
            ><v-card-subtitle class="font-weight-bold">
              <span>{{ StoreObj.commentIntegrity }}</span></v-card-subtitle
            ></v-col
          >
          <!-- <v-col cols="4" md="4" class="mt-n9" v-if="StoreObj.scoreIntegrity != ''">
            <v-rating
              v-model="StoreObj.scoreIntegrity"
              background-color="green"
              color="green"
              small
            ></v-rating>
          </v-col> -->
        </v-row>
        <v-row>
          <v-col cols="7" md="7"  class="mt-n9" v-if="StoreObj.commentAttendence != ''"
            ><v-card-subtitle>Attendance</v-card-subtitle></v-col
          >
          <v-col cols="5" md="5"  class="mt-n9" v-if="StoreObj.commentAttendence != ''"
            ><v-card-subtitle class="font-weight-bold">
              <span>{{ StoreObj.commentAttendence }}</span></v-card-subtitle
            ></v-col
          >
          <!-- <v-col cols="4" md="4" v-if="StoreObj.scoreAttendence != ''">
            <v-rating
              v-model="StoreObj.scoreAttendence"
              background-color="green"
              color="green"
              small
            ></v-rating>
          </v-col> -->
        </v-row>
        <v-row class="">
          <v-col
            cols="7"
            md="7"
            class="mt-n9"
            v-if="StoreObj.eligibleforhire != ''"
            ><v-card-subtitle>Eligible For Re-hire</v-card-subtitle></v-col
          >
          <v-col
            cols="5"
            md="5"
            class="mt-n9"
            v-if="StoreObj.eligibleforhire != ''"
            ><v-card-subtitle class="font-weight-bold">
              <span>{{ StoreObj.eligibleforhire }}</span></v-card-subtitle
            ></v-col
          >
        </v-row>
        <v-row class="">
          <v-col cols="7" md="7" class="mt-n9" v-if="StoreObj.complaints != ''"
            ><v-card-subtitle>Complaints</v-card-subtitle></v-col
          >
          <v-col cols="5" md="5" class="mt-n9" v-if="StoreObj.complaints != ''"
            ><v-card-subtitle class="font-weight-bold">
              <span>{{ StoreObj.complaints }}</span></v-card-subtitle
            ></v-col
          >
        </v-row>
        <v-row class="">
          <v-col
            cols="7"
            md="7"
            class="mt-n9"
            v-if="StoreObj.natureofseperation != ''"
            ><v-card-subtitle>Nature of Separation</v-card-subtitle></v-col
          >
          <v-col
            cols="5"
            md="5"
            class="mt-n9"
            v-if="StoreObj.natureofseperation != ''"
            ><v-card-subtitle class="font-weight-bold">
              <span>{{ StoreObj.natureofseperation }}</span></v-card-subtitle
            ></v-col
          >
        </v-row>
        <v-row class="">
          <v-col cols="7" md="7" class="mt-n9" v-if="StoreObj.complaints != ''"
            ><v-card-subtitle>Complaints</v-card-subtitle></v-col
          >
          <v-col cols="5" md="5" class="mt-n9" v-if="StoreObj.complaints != ''"
            ><v-card-subtitle class="font-weight-bold">{{
              StoreObj.complaints
            }}</v-card-subtitle></v-col
          >
        </v-row>
        <!-- <v-row class="">
          <v-col
            cols="7"
            md="7"
            class="mt-n9"
            v-if="StoreObj.contractviolation != '' || StoreObj.contractviolation != null" 
            ><v-card-subtitle>Contractual Violations</v-card-subtitle></v-col
          >
          <v-col
            cols="5"
            md="5"
            class="mt-n9"
            v-if="StoreObj.contractviolation != '' || StoreObj.contractviolation != null"
            ><v-card-subtitle class="font-weight-bold">
              <span>{{ StoreObj.contractviolation }}</span></v-card-subtitle
            ></v-col
          >
        </v-row> -->
        <v-row class="">
          <v-col
            cols="7"
            md="7"
            class="mt-n9"
            v-if="StoreObj.additionalcomments != ''"
            ><v-card-subtitle>Additional Comments</v-card-subtitle></v-col
          >
          <v-col
            cols="5"
            md="5"
            class="mt-n9"
            v-if="StoreObj.additionalcomments != ''"
            ><v-card-subtitle class="font-weight-bold">{{
              StoreObj.additionalcomments
            }}</v-card-subtitle></v-col
          >
        </v-row>
      </v-card-text>
      <v-card-actions class="pt-0 pb-4">
        <v-spacer></v-spacer>
        <v-btn
          small
          class="cancelButton white--text mt-n6 mr-1"
          @click="closeAdditionalDetailsEmit()"
          ><v-icon small class="mr-1">mdi-close</v-icon>Close</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialogAdditionalDetails: Boolean,
    StoreObj: Object,
  },
  data: () => ({
    details: null,
  }),
  watch: {
    dialogAdditionalDetails(val) {
      var newarr = [];
      if (val == true) {
        for (let i in this.StoreObj) {
          if (i == "address") {
            continue;
          }
          newarr.push(this.StoreObj[i]);
        }
      }
      this.details = newarr.every((e) => e == "");
    },
  },
  mounted() {
    this.checkObject();
  },
  methods: {
    checkObject() {
    },
    closeAdditionalDetailsEmit() {
      this.details = null;
      this.$emit("clicked", false);
    },
  },
};
</script>

<style></style>
