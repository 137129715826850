/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const ListVeirifiedDocuments = /* GraphQL */ `
  query ListVeirifiedDocuments($input: ListVeirifiedDocumentsInput) {
    ListVeirifiedDocuments(input: $input)
  }
`;
export const RLGetCurrentUserDetails = /* GraphQL */ `
  query RLGetCurrentUserDetails($input: RLGetCurrentUserDetailsInput) {
    RLGetCurrentUserDetails(input: $input)
  }
`;
export const GetCurrentUserDetails = /* GraphQL */ `
  query GetCurrentUserDetails($input: EmailIDInput) {
    GetCurrentUserDetails(input: $input)
  }
`;
export const ListUsers = /* GraphQL */ `
  query ListUsers($input: EmailIDInput) {
    ListUsers(input: $input)
  }
`;
export const listCredentials = /* GraphQL */ `
  query ListCredentials($input: ListCredentialsInput) {
    listCredentials(input: $input)
  }
`;
export const YearlyReport = /* GraphQL */ `
  query YearlyReport($input: yearlyReportInput) {
    YearlyReport(input: $input)
  }
`;
export const ListCountries = /* GraphQL */ `
  query ListCountries($input: ListCountriesInput!) {
    ListCountries(input: $input)
  }
`;
export const ListPDFTemplates = /* GraphQL */ `
  query ListPDFTemplates($input: ListPDFTemplatesInput) {
    ListPDFTemplates(input: $input)
  }
`;
export const ListMedia = /* GraphQL */ `
  query ListMedia($input: listMediaInput) {
    ListMedia(input: $input)
  }
`;
export const ListVerificationRequests = /* GraphQL */ `
  query ListVerificationRequests($input: ListVerificationRequestsInput) {
    ListVerificationRequests(input: $input)
  }
`;
export const PreviewPDFTemplate = /* GraphQL */ `
  query PreviewPDFTemplate($input: PreviewPDFTemplateInput) {
    PreviewPDFTemplate(input: $input)
  }
`;
export const FileConvertS3UrlGenerate = /* GraphQL */ `
  query FileConvertS3UrlGenerate($input: FileConvertS3UrlGenerateInput!) {
    FileConvertS3UrlGenerate(input: $input)
  }
`;
export const RL_listReasonOfLeaving = /* GraphQL */ `
  query RL_listReasonOfLeaving($input: RL_ListReasonOfLeavingInput!) {
    RL_listReasonOfLeaving(input: $input)
  }
`;
export const ListIssuerNatureOfSeparation = /* GraphQL */ `
  query ListIssuerNatureOfSeparation(
    $input: ListIssuerNatureOfSeparationInput!
  ) {
    ListIssuerNatureOfSeparation(input: $input)
  }
`;
