<template>
  <div>
    <v-dialog v-model="dialogverifyLetterMore" max-width="1200">
      <Overlay :overlay="overlay" :count="count" />
      <v-card class="elevation-0 mt-n2">
        <v-toolbar dense class="elevation-0 appbar">
          <div class="white--text">Verify Relieving Letter</div>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeVerifyEmit()"
            ><v-icon color="white">mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text class="py-0">
          <v-row no-gutters>
            <v-col
              v-if="results == false"
              cols="12"
              xs="12"
              sm="12"
              :md="results == false ? 6 : 6"
              align="center"
            >
              <v-card
                class="BorderPrimary elevation-0 ml-7 mr-7 mt-8 mb-9"
                dense
                align="center"
                justify="center"
                :height="heightRight"
                @drop.prevent="onDrop($event)"
                @dragover.prevent="dragover = true"
                @dragenter.prevent="dragover = true"
                @dragleave.prevent="dragover = false"
                @click="handleUpload"
              >
                <v-card-text style="padding-top: 220px">
                  <v-btn
                    small
                    tile
                    icon
                    :class="[dragover ? 'mt-2, mb-6' : 'mt-12']"
                    class="mr-2 text-capitalize"
                    color="green"
                  >
                    <v-icon size="60">mdi-cloud-upload</v-icon>
                    <input
                      ref="excel-upload-input"
                      accept="application/pdf"
                      class="excel-upload-input"
                      type="file"
                      @change="handleClick"
                    />
                  </v-btn>
                  <p
                    v-if="uploadedFiles.length == 0"
                    class="mt-4 font-weight-regular"
                    style="color: black"
                  >
                    Drop your file here, or click to upload.
                  </p>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col
              v-if="results == true"
              cols="12"
              xs="12"
              sm="12"
              md="6"
              align="center"
            >
              <v-card
                v-if="responseStatus == 'CONFIRMED'"
                class="BorderGreen elevation-0 ma-7"
                color="#ecfaec"
                :height="heightRight"
              >
                <v-row class="d-flex justify-space-between">
                  <v-col cols="12">
                    <div class="pt-4" style="text-align: end">
                      <v-btn
                        dark
                        small
                        class="ma-2"
                        color="green"
                        @click.stop="removeFile(uploadedFiles[0].name)"
                        >Close
                        <v-icon class="ml-1" small>mdi-close</v-icon></v-btn
                      >
                    </div>
                  </v-col>

                  <!-- <v-col cols="2" md="2">
                    <v-btn
                      small
                      class="mt-3"
                      dark
                      color="green"
                      @click.stop="
                        (stepVerifyCredentials = 1),
                          (error = false),
                          (dragover = false),
                          (uploadedFiles = []),
                          (results = false)
                      "
                      >Close</v-btn
                    >
                  </v-col> -->
                </v-row>

                <v-card-text align="left" class="">
                  <!-- 
                  <v-row>
                    <v-col cols='12' md="6" lg="6" sm="12" xs="12">

                    </v-col>
                      <v-col cols='12' md="6" lg="6" sm="12" xs="12">


                      </v-col>
                  </v-row> -->

                  <v-row no-gutters>
                    <v-col cols="12" md="12" lg="12" xl="12" sm="12" xs="12">
                      <div
                        style="
                          display: flex;
                          justify-content: center;
                          align-items: center;
                          height: 100%;
                        "
                      >
                        <!-- <v-img
                          contain
                          src="@/assets/Certified.png"
                          width="150px"
                          height="150px"
                        ></v-img> -->
                        <div class="largeFontSizePrimaryGreen">
                          This Relieving Letter is Valid<v-icon
                            color="green"
                            class="mr-2"
                            large
                            dark
                            >mdi-check-decagram</v-icon
                          >
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="12" md="6" lg="6" sm="12" xs="12">
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title>Issued On</v-list-item-title>
                          <v-list-item-subtitle
                            v-text="credentials.issued_on"
                          ></v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" md="6" lg="6" sm="12" xs="12">
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title
                            >Issued By Organization</v-list-item-title
                          >
                          <v-list-item-subtitle
                            v-text="credentials.issued_at"
                          ></v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" md="6" lg="6" sm="12" xs="12">
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title
                            >Issued By User Name</v-list-item-title
                          >
                          <v-list-item-subtitle
                            v-text="credentials.issued_by_user_name"
                          ></v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" md="6" lg="6" sm="12" xs="12">
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title
                            >Issued To Receipient
                          </v-list-item-title>
                          <v-list-item-subtitle
                            v-text="credentials.issued_to"
                          ></v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" md="6" lg="6" sm="12" xs="12">
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title>Employee Id </v-list-item-title>
                          <v-list-item-subtitle
                            v-text="credentials.emp_Id"
                          ></v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" md="6" lg="6" sm="12" xs="12">
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title>Worked From </v-list-item-title>
                          <v-list-item-subtitle
                            v-text="credentials.worked_from"
                          ></v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" md="6" lg="6" sm="12" xs="12">
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title>Worked Till </v-list-item-title>
                          <v-list-item-subtitle
                            v-text="credentials.worked_till"
                          ></v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" md="6" lg="6" sm="12" xs="12">
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title>Designation </v-list-item-title>
                          <v-list-item-subtitle
                            v-text="credentials.designation"
                          ></v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                  <v-row v-if="credentials.department !== undefined">
                    <v-col cols="12" md="6" lg="6" sm="12" xs="12">
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title>Department </v-list-item-title>
                          <v-list-item-subtitle
                            v-text="credentials.department"
                          ></v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item> </v-col
                  ></v-row>

                  <!-- <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>Additional Comments</v-list-item-title>
                      <v-list-item-subtitle>{{
                        credentials.comments ? credentials.comments : "-"
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item> -->

                  <v-card-text
                    v-if="detailsOfEmployee == 1"
                    class="font-weight-bold"
                    >Additional Details Not Given</v-card-text
                  >

                  <v-card-text v-if="detailsOfEmployee == 2" class="ml-n4">
                    <v-card-title>Additional Details:</v-card-title>

                    <v-row>
                      <v-col
                        cols="12"
                        md="12"
                        lg="12"
                        sm="12"
                        xs="12"
                        v-if="
                          credentials.companyAddress !== '' &&
                          credentials.companyAddress !== null
                        "
                      >
                        <v-list-item one-line>
                          <v-list-item-content>
                            <v-list-item-title
                              >Company Address</v-list-item-title
                            >
                            <v-list-item-subtitle
                              v-text="credentials.companyAddress"
                            ></v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                        lg="6"
                        xl="6"
                        sm="12"
                        xs="12"
                        v-if="
                          credentials.salary !== '' &&
                          credentials.salary !== null
                        "
                      >
                        <v-list-item one-line>
                          <v-list-item-content>
                            <v-list-item-title
                              >Last Drawn Salary</v-list-item-title
                            >
                            <v-list-item-subtitle
                              v-text="credentials.salary"
                            ></v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>

                      <v-col
                        cols="12"
                        md="6"
                        lg="6"
                        sm="12"
                        xs="12"
                        v-if="
                          credentials.remuneration !== '' &&
                          credentials.remuneration !== null
                        "
                      >
                        <v-list-item one-line>
                          <v-list-item-content>
                            <v-list-item-title
                              >Additional Remuneration</v-list-item-title
                            >
                            <v-list-item-subtitle
                              v-text="credentials.remuneration"
                            ></v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>

                      <v-col
                        cols="12"
                        md="6"
                        lg="6"
                        sm="12"
                        xs="12"
                        v-if="
                          credentials.supervisordetails !== '' &&
                          credentials.supervisordetails !== null
                        "
                      >
                        <v-list-item one-line>
                          <v-list-item-content>
                            <v-list-item-title
                              >Supervisor's Name
                            </v-list-item-title>
                            <v-list-item-subtitle
                              v-text="credentials.supervisordetails"
                            ></v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>

                      <v-col
                        cols="12"
                        md="6"
                        lg="6"
                        sm="12"
                        xs="12"
                        v-if="
                          credentials.reasonofleaving !== '' &&
                          credentials.reasonofleaving !== null
                        "
                      >
                        <v-list-item one-line>
                          <v-list-item-content>
                            <v-list-item-title
                              >Reason For Leaving</v-list-item-title
                            >
                            <v-list-item-subtitle
                              v-text="credentials.reasonofleaving"
                            ></v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>

                      <v-col
                        cols="12"
                        md="6"
                        lg="6"
                        sm="12"
                        xs="12"
                        v-if="
                          credentials.natureofemployment !== '' &&
                          credentials.natureofemployment !== null
                        "
                      >
                        <v-list-item one-line>
                          <v-list-item-content>
                            <v-list-item-title
                              >Nature of Employment</v-list-item-title
                            >
                            <v-list-item-subtitle
                              v-text="credentials.natureofemployment"
                            ></v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>

                      <v-col
                        cols="12"
                        md="6"
                        lg="6"
                        sm="12"
                        xs="12"
                        v-if="
                          credentials.lastlocationofwork !== '' &&
                          credentials.lastlocationofwork !== null
                        "
                      >
                        <v-list-item one-line>
                          <v-list-item-content>
                            <v-list-item-title
                              >Last Location of work</v-list-item-title
                            >
                            <v-list-item-subtitle
                              v-text="credentials.lastlocationofwork"
                            ></v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col
                        dense
                        cols="12"
                        md="6"
                        lg="6"
                        sm="12"
                        xs="12"
                        v-if="
                          credentials.dues !== '' && credentials.dues !== null
                        "
                      >
                        <v-list-item one-line>
                          <v-list-item-content>
                            <v-list-item-title
                              >Exit Formalities Completed
                              completed</v-list-item-title
                            >
                            <v-list-item-subtitle
                              v-text="credentials.dues"
                            ></v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>

                      <v-col
                        dense
                        cols="12"
                        md="6"
                        lg="6"
                        sm="12"
                        xs="12"
                        v-if="
                          credentials.responsibilities !== '' &&
                          credentials.responsibilities !== null
                        "
                      >
                        <v-list-item one-line>
                          <v-list-item-content>
                            <v-list-item-title
                              >Duties and Responsibilities</v-list-item-title
                            >
                            <v-list-item-subtitle
                              v-text="credentials.responsibilities"
                            ></v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                        lg="6"
                        sm="12"
                        xs="12"
                        v-if="
                          credentials.attitude !== '' &&
                          credentials.attitude !== null
                        "
                      >
                        <v-list-item one-line>
                          <v-list-item-content>
                            <v-list-item-title
                              >Attitude and reputation</v-list-item-title
                            >
                            <v-list-item-subtitle
                              v-text="credentials.attitude"
                            ></v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>

                      <v-col
                        cols="12"
                        md="6"
                        lg="6"
                        sm="12"
                        xs="12"
                        v-if="
                          credentials.performance !== '' &&
                          credentials.performance !== null
                        "
                      >
                        <v-list-item one-line>
                          <v-list-item-content>
                            <v-list-item-title>Performance</v-list-item-title>
                            <v-list-item-subtitle
                              v-text="credentials.performance"
                            ></v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                        lg="6"
                        sm="12"
                        xs="12"
                        v-if="
                          credentials.integrity !== '' &&
                          credentials.integrity !== null
                        "
                      >
                        <v-list-item one-line>
                          <v-list-item-content>
                            <v-list-item-title>Integrity</v-list-item-title>
                            <v-list-item-subtitle
                              v-text="credentials.integrity"
                            ></v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>

                      <v-col
                        cols="12"
                        md="6"
                        lg="6"
                        sm="12"
                        xs="12"
                        v-if="
                          credentials.attendence !== '' &&
                          credentials.attendence !== null
                        "
                      >
                        <v-list-item one-line>
                          <v-list-item-content>
                            <v-list-item-title>Attendence</v-list-item-title>
                            <v-list-item-subtitle
                              v-text="credentials.attendence"
                            ></v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                        lg="6"
                        sm="12"
                        xs="12"
                        v-if="
                          credentials.hire !== '' && credentials.hire !== null
                        "
                      >
                        <v-list-item one-line>
                          <v-list-item-content>
                            <v-list-item-title
                              >Eligible to Re-Hire</v-list-item-title
                            >
                            <v-list-item-subtitle
                              v-text="credentials.hire"
                            ></v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <div style="color: green">
                    <u
                      class="ml-4"
                      style="cursor: pointer"
                      @click="checkOnBlockChainMethod()"
                      color="green"
                      >Click here to view the blockchain record</u
                    >
                  </div>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
              <v-card
                v-if="responseStatus == 'MINING'"
                class="BorderOrange elevation-0"
                color="#fff6e5"
                :height="heightRight"
              >
                <v-card-title class="pt-3">
                  <div class="largeFontSizePrimaryOrange">
                    Writing To Blockchain
                  </div>
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
              <v-card
                color="#f6dfdf"
                v-if="responseStatus == 'ERROR'"
                :height="heightRight"
                class="elevation-0 BorderRed elevation-0 ml-7 mr-7 mt-8 mb-9"
              >
                <v-row>
                  <v-col cols="12">
                    <div style="text-align: end">
                      <v-btn
                        dark
                        small
                        class="ma-4"
                        @click.stop="removeFile(uploadedFiles[0].name)"
                        >Close
                        <v-icon small dark class="ml-1">mdi-close</v-icon>
                      </v-btn>
                    </div>
                  </v-col>
                  <v-col cols="12" md="12">
                    <!--  <div class="largeFontSizeRed" style="margin-top: 20px">
                      Invalid Relieving Letter
                    </div> -->

                    <div
                      class="largeFontSizePrimaryRed"
                      style="text-align: center; margin-bottom: 400px"
                    >
                      This Relieving Letter is Invalid
                      <v-icon color="red" large dark class="mr-2"
                        >mdi-clipboard-remove</v-icon
                      >
                    </div>
                  </v-col>
                </v-row>
                <v-card-actions>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col
              cols="12"
              xs="12"
              sm="12"
              :md="results == false ? 6 : 6"
              align="center"
              class=""
            >
              <v-card
                class="elevation-0"
                style="padding-top: 100px"
                align="center"
                v-if="results == false"
              >
                <v-row class="d-flex justify-center">
                  <v-col cols="6" md="6" sm="6">
                    <v-img
                      max-width="500px"
                      src="@/assets/VerifyCredentials.jpeg"
                    ></v-img>
                    <!-- <v-img
                      class="hidden-md-and-down"
                      max-width="500px"
                      src="@/assets/Verifed_RL.svg"
                    ></v-img> -->
                  </v-col>
                </v-row>
              </v-card>
              <v-card
                class="elevation-0 mx-8 mt-12"
                align="center"
                v-if="results == false"
              >
                <div class="xlargeFontSize green--text hidden-md-and-down">
                  Verify Relieving Letter
                </div>
              </v-card>
              <v-row
                v-if="results == true"
                class="BorderPrimary ml-7 mr-7 mt-8 mb-9"
              >
                <!-- <div class="xlargeFontSize py-2 pl-2">Selected Document</div> -->
                <iframe
                  :height="heightPDF"
                  :src="pdfsrc"
                  width="100%"
                  frameborder="0"
                ></iframe>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { API } from "aws-amplify";
import Overlay from "@/components/Extras/OverlayForVerifyLetter.vue";
export default {
  props: {
    dialogUpload: Boolean,
    countCreds: Number,
    dialogverifyLetterMore: Boolean,
  },
  components: {
    Overlay,
  },
  data: () => ({
    text: "",
    data: "",
    count: 0,
    height: 0,
    pdfsrc: "",
    snackbar: false,
    dragover: false,
    uploadedFiles: [],
    multiple: false,
    overlay: false,
    SnackBarComponent: {},
    stepVerifyCredentials: 1,
    dialogViewCredentials: false,
    listCredentialsHeaders: [
      { text: "Issued To", value: "issued_to_email_id" },
      { text: "Issued On", value: "issued_on" },
      { text: "Issued At", value: "issued_at" },
      // { text: "Issued By", value: "issued_by_user_name" },
    ],
    error: false,
    results: false,
    Height: "400px",
    verifyInterval: 0,
    heightRight: 0,
    credentials: {
      issued_to: "",
      issued_on: "",
      issued_at: "",
      issued_by_user_name: "",
      additional_comments: "",
      emp_Id: "",
      worked_from: "",
      worked_till: "",
      designation: "",
      department: "",
      companyAddress: "",
      companyNumber: "",
      salary: "",
      remuneration: "",
      supervisordetails: "",
      reasonofleaving: "",
      natureofemployment: "",
      lastlocationofwork: "",
      dues: "",
      responsibilities: "",
      attitude: "",
      performance: "",
      integrity: "",
      attendence: "",
      hire: "",
    },
    responseStatus: "",
    errorStatus: "",
    detailsOfEmployee: null,
  }),
  watch: {
    countCreds() {
      this.results = false;
    },
    dialogUpload() {
      this.uploadedFiles = [];
    },
  },
  mounted() {
    this.height = window.innerHeight - 210;
    this.heightPDF = window.innerHeight - 175;
    this.heightRight = window.innerHeight - 170;
  },
  methods: {
    closeVerifyEmit() {
      this.$emit("clicked", false);
    },
    checkOnBlockChainMethod() {
      window.open(
        `https://viewblock.io/arweave/tx/${this.credentials.transaction_id}`
      );
    },
    dialogViewCredentialsEmit() {
      this.dialogViewCredentials = false;
    },
    async VerifyCredentials(data) {
      var self = this;
      self.count = 60;
      self.overlay = true;
      const path = "verifycredentials";
      const myInit = {
        body: {
          command: "verifyCredential",
          credential_hash: data,
          issuer_email_id: this.$store.getters.get_user_email,
        },
      };
      API.post("RESTAPI", path, myInit)
        .then((response) => {
          // console.log(response);
          self.responseStatus = response.status;
          self.count = 80;
          self.overlay = false;
          self.results = true;
          if (!response.errorType) {
            let arrdetails = [];
            if (self.responseStatus == "CONFIRMED") {
              if (!response.data.valid_till) {
                if (response.data.additional_details) {
                  for (let i in response.data.additional_details) {
                    if (i == "address") {
                      continue;
                    }
                    arrdetails.push(response.data.additional_details[i]);
                  }
                  // console.log(arrdetails);
                  if (arrdetails.every((e) => e == "")) {
                    self.detailsOfEmployee = 1;
                    self.credentials = {
                      transaction_id: response.data.transaction_id,
                      issued_to: response.data.issued_to,
                      issued_on: response.data.issued_on,
                      issued_at: response.data.issued_at,
                      issued_by_user_name: response.data.issued_by_user_name,
                      certificate_Number: response.data.credential_number,
                      comments: response.data.comments,
                      emp_Id: response.data.employee_id,
                      worked_from: response.data.worked_from,
                      worked_till: response.data.worked_till,
                      designation: response.data.designation,
                      department: response.data.department,
                    };
                    self.expiryDate = false;
                  } else {
                    self.detailsOfEmployee = 2;
                    // console.log("I have the main details");
                    self.credentials = {
                      transaction_id: response.data.transaction_id,
                      issued_to: response.data.issued_to,
                      issued_on: response.data.issued_on,
                      issued_at: response.data.issued_at,
                      issued_by_user_name: response.data.issued_by_user_name,
                      certificate_Number: response.data.credential_number,
                      comments: response.data.comments,
                      emp_Id: response.data.employee_id,
                      worked_from: response.data.worked_from,
                      worked_till: response.data.worked_till,
                      designation: response.data.designation,
                      department: response.data.department,
                      companyAddress: response.data.additional_details.address,
                      companyNumber:
                        response.data.additional_details.companyNumber,
                      salary: response.data.additional_details.salary,
                      remuneration:
                        response.data.additional_details.remuneration,
                      supervisordetails:
                        response.data.additional_details.supervisordetails,
                      reasonofleaving:
                        response.data.additional_details.reasonofleaving,
                      natureofemployment:
                        response.data.additional_details.natureofemployment,
                      lastlocationofwork:
                        response.data.additional_details.lastlocationofwork,
                      dues: response.data.additional_details.dues,
                      responsibilities:
                        response.data.additional_details.commentDuties,
                      attitude:
                        response.data.additional_details.commentAttitude,
                      performance:
                        response.data.additional_details.commentPerformance,
                      integrity:
                        response.data.additional_details.commentIntegrity,
                      attendence:
                        response.data.additional_details.commentAttendence,
                      hire: response.data.additional_details.eligibleforhire,
                    };
                    this.expiryDate = false;
                  }
                } else {
                  this.detailsOfEmployee = 2;
                  // console.log("I dont have");
                }
              } else {
                if (response.data.valid_till > new Date().getTime()) {
                  self.credentials = {
                    transaction_id: response.data.transaction_id,
                    issued_to: response.data.issued_to,
                    issued_on: response.data.issued_on,
                    issued_at: response.data.issued_at,
                    valid_till: response.data.valid_till,
                    issued_by_user_name: response.data.issued_by_user_name,
                    certificate_Number: response.data.credential_number,
                    comments: response.data.comments,
                  };
                  this.expiryDate = true;
                } else {
                  self.credentials = {
                    transaction_id: response.data.transaction_id,
                    issued_to: response.data.issued_to,
                    issued_on: response.data.issued_on,
                    issued_at: response.data.issued_at,
                    valid_till: response.data.valid_till,
                    issued_by_user_name: response.data.issued_by_user_name,
                    certificate_Number: response.data.credential_number,
                    comments: response.data.comments,
                  };
                  self.responseStatus = "ERROR";
                  self.errorStatus = "EXPIRED";
                }
              }
            }
          } else {
            self.responseStatus = "ERROR";
            self.errorStatus = "ERROR";
          }
        })
        .catch((error) => {
          // console.warn(error);
          self.count = 0;
          self.overlay = true;
          self.results = true;
        });
    },
    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return "0 Bytes";
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    },
    uploadFiles() {
      this.count = 0;
      this.overlay = true;
      var fileReader = new FileReader();
      this.count = 20;
      fileReader.readAsDataURL(this.uploadedFiles[0]);
      var self = this;
      fileReader.onload = async function (val) {
        self.count = 40;
        self.pdfsrc = val.target.result;
        var base64FileContent = fileReader.result.split(",");
        var buf = Buffer.from(base64FileContent[1], "base64");
        const crypto = require("crypto");
        let data = crypto.createHash("sha256").update(buf).digest("hex");
        self.VerifyCredentials(data);
      };
    },
    handleUpload() {
      this.$refs["excel-upload-input"].click();
    },
    handleClick(e) {
      this.uploadedFiles = [];
      this.dragover = true;
      if (e.target.files.length == 1) {
        for (let i = 0; i < e.target.files.length; i++) {
          this.uploadedFiles.push(e.target.files[i]);
        }
        this.uploadFiles();
      }
    },
    closeDialog() {
      this.uploadedFiles = [];
    },
    removeFile(fileName) {
      const index = this.uploadedFiles.findIndex(
        (file) => file.name === fileName
      );
      if (index > -1) this.uploadedFiles.splice(index, 1);
      this.dragover = false;
      this.error = false;
      this.results = false;
    },
    onDrop(e) {
      this.uploadedFiles = [];
      if (e.dataTransfer.files.length == 1) {
        for (let i = 0; i < e.dataTransfer.files.length; i++) {
          this.uploadedFiles.push(e.dataTransfer.files[i]);
        }
        this.uploadFiles();
      }
    },
  },
};
</script>
<style scoped>
.excel-upload-input {
  display: none;
  z-index: -9999;
}

.drop {
  border: 2px dashed #bbb;
  width: 600px;
  height: 160px;
  line-height: 160px;
  margin: 0 auto;
  font-size: 24px;
  border-radius: 5px;
  text-align: center;
  color: #bbb;
  position: relative;
}

.v-card {
  display: flex !important;
  flex-direction: column;
}

.v-card__text {
  flex-grow: 1;
  overflow: auto;
}

.issued-info {
  display: flex;
  align-items: center;
}

.label {
  margin-right: 10px;
}

.value {
  color: #666c66;
}
</style>
